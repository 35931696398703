@import '~antd/dist/antd.css';

tr.info-row {
  background-color: #e6f7ff;
  color: #000000;
}

tr.info-row-1 {
  background-color: #d0e0ff;
  color: #000000;
}

tr.success-row {
  background-color: #f6ffed;
  color: #000000;
}

tr.success-row-1 {
  background-color: #ddffee;
  color: #000000;
}

tr.warning-row {
  background-color: #fffbe6;
  color: #000000;
}

tr.warning-row-1 {
  background-color: #fffacd;
  color: #000000;
}

tr.counting-row {
  background-color: #fffacd;
  color: #000000;
}

tr.counted-row {
  background-color: #fffef4;
  color: #000000;
}

tr.error-row {
  background-color: #ff8077;
  color: #000000;
}

tr.error-row-1 {
  background-color: #ff685e;
  color: #000000;
}

tr.hex-D0E0FF {
  background-color: #D0E0FF;
  color: #000000;
}

tr.hex-DDFFEE {
  background-color: #DDFFEE;
  color: #000000;
}

tr.hex-FFFACD {
  background-color: #FFFACD;
  color: #000000;
}

tr.hex-FECBCF {
  background-color: #FECBCF;
  color: #000000;
}

tr.hex-7FFFD4 {
  background-color: #7FFFD4;
  color: #000000;
}

tr.hex-F5F5DC {
  background-color: #F5F5DC;
  color: #000000;
}

tr.hex-F7E7CE {
  background-color: #F7E7CE;
  color: #000000;
}

tr.hex-EDC9AF {
  background-color: #EDC9AF;
  color: #000000;
}

tr.hex-00FFFF {
  background-color: #00FFFF;
  color: #000000;
}

tr.hex-E0B0FF {
  background-color: #E0B0FF;
  color: #000000;
}

tr.hex-FFE5B4 {
  background-color: #FFE5B4;
  color: #000000;
}

tr.hex-CCCCFF {
  background-color: #CCCCFF;
  color: #000000;
}

tr.hex-C0C0C0 {
  background-color: #C0C0C0;
  color: #000000;
}

tr.hex-D2B48C {
  background-color: #D2B48C;
  color: #000000;
}

tr.hex-D2B4ED {
  background-color: #D2B4ED;
  color: #000000;
}

tr.hex-F0EFB0 {
  background-color: #F0EFB0;
  color: #000000;
}