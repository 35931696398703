/* font settings for modal in the dashboard*/
.modalinfo-row {
  border-bottom: 1px solid;
  padding-top: 10px;
  padding-bottom: 10px;
}

.modalinfo {
  font-family: 'Roboto', sans-serif;
  font-size: 13.8px;
}

.modalinfo-details {
  font-family: 'Roboto', sans-serif;
  font-size: 13.8px;
  /*
  font-style: italic;
  font-weight: bold;
  text-align: center;
  */
}

.sls-ord-dialog .ant-modal-header {
  background-image: radial-gradient(circle at top left, #9ea7fc, #6eb4f7);
}

.sls-ord-dialog .ant-modal-title {
  color: black;
  text-align: center;
  font-size: large;
}
