.wippicklist {
  text-align: center;
  background-image: radial-gradient(circle at top left, #ff735a, #ffa999);
  font-weight: 500;
}

.wiploadlist {
  text-align: center;
  background-image: radial-gradient(circle at top left, #ff735a, #ffa999);
  font-weight: 500;
}

.wipwhsejobb {
  text-align: center;
  background-image: radial-gradient(circle at top left, #ff735a, #ffa999);
  font-weight: 500;
}

.completeloadlistt {
  text-align: center;
  background-image: radial-gradient(circle at top left, #ff735a, #ffa999);
  font-weight: 500;
}
