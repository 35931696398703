.sls-ord-summary .ant-statistic-title {
    font-size: small;
}

.summary-card {
    width: 8.5cm;
    margin-top: 1rem;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    text-align: center;
}

.sls-ord-header, .lsp-sls-ord-header {
    background-image: radial-gradient(circle at top left, #9ea7fc, #6eb4f7);
    font-weight: 600;
}

.pick-list-header {
    background-image: radial-gradient(circle at top left, #ffb74e, #ff9985);
    font-weight: 600;
}

.load-list-header {
    background-image: radial-gradient(circle at top left, #ff735a, #ffa999);
    font-weight: 600;
}
